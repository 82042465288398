<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-divider title="基本信息"></x-divider>
            <x-detail-item prop="baoSunDanID" label="报损单ID" />
            <x-detail-item prop="baoSunSJ" label="报损时间" />
            <x-detail-item prop="baoSunShangPinZhongShu" label="报损商品种数" />
            <x-detail-item prop="baoSunZongJinE" label="报损总金额" />
            <x-detail-item prop="baoSunRen" label="报损人" />
            <x-detail-item prop="baoSunRenID" label="报损人ID" />
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item prop="title" label="主要事项" />
            <x-divider title="审核信息"></x-divider>
            <x-detail-item prop="applicant" label="申请人" />
            <x-detail-item prop="applyDate" label="申请时间" class="w66"/>
            <x-detail-item label="审核状态">
                <x-dict-show :code="entity.auditStatus" dictType="T_AUDIT_STATUS"/>
            </x-detail-item>
            <x-detail-item prop="auditorName" label="审核人" />
            <x-detail-item prop="auditDate" label="审核日期" />
            <x-detail-item prop="auditMind" label="审核意见" class="line"/>
        </x-detail>
        <x-divider title="报损信息详情"></x-divider>
        <el-table :data="entity.baoSunXinXiXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" type="index" />
            <el-table-column width="150" prop="baoSunCangKuMC" label="报损仓库名称" />
            <el-table-column width="150" prop="baoSunShangPinMC" label="报损商品名称" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="chengBenJia" label="成本价" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="150" prop="dangQianKuCunSL" label="当前库存数量" />
            <el-table-column width="150" prop="baoSunSL" label="报损数量" />
            <el-table-column width="150" prop="baoSunJinE" label="报损金额" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/kcgl/BaoSunXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                baoSunDanID: "", // 报损单ID
                baoSunSJ: "", // 报损时间
                baoSunShangPinZhongShu: "", // 报损商品种数
                baoSunZongJinE: "", // 报损总金额
                baoSunRen: "", // 报损人
                baoSunRenID: "", // 报损人ID
                beiZhu: "", // 备注
                title: "", // 主要事项
                applicant: "", // 申请人
                applyDate: "", // 申请日期
                auditStatus: "", // 审核状态
                auditorName: "", // 审核人
                auditDate: "", // 审核日期
                auditMind: "", // 审核意见
                baoSunXinXiXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>